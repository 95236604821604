import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/Layout'
import Hero from '../components/Hero'

/**
 * Display a dynamic page template
 *
 * @param {string} templateKey
 * @param {string} title
 * @param {string} subtitle
 * @param {string} pageClass
 * @param {object} image
 * @param {boolean} showCTA Default: true | Toggles CTA button in Hero
 */
const PageTemplate = ({
  title,
  h2Title,
  ctaText,
  pageIdentifier,
  seoTitle,
  seoDescription,
  pageClass,
  subtitle,
  image,
  showCTA,
  body,
  disableMdx,
  imageAlt,
}) => {
  return (
    <Layout
      className={`is-page-${pageIdentifier} ${pageClass ? pageClass : ''} ${showCTA ? '' : 'hide-cta'}`}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      ctaText={ctaText || ''}
    >
      <Hero
        title={title}
        subtitle={subtitle}
        image={image}
        showCTA={showCTA}
        h2Title={h2Title}
        imageAlt={imageAlt || title}
      />
      <div className="container content content-body is-narrow">
        {/* Markdown fallback for CMS Previews */}
        {disableMdx === true || disableMdx === 'true' ? (
          body
        ) : (
          <MDXRenderer>{body}</MDXRenderer>
        )}
      </div>
    </Layout>
  )
}

export { PageTemplate }

/**
 * Create Netlify CMS-compatible Preview Component
 */
const Page = ({ data }) => {
  return (
    <PageTemplate
      title={data.mdx.frontmatter.title}
      h2Title={data.mdx.frontmatter.h2Title}
      pageIdentifier={data.mdx.fields.pageIdentifier}
      seoTitle={data.mdx.frontmatter.seoTitle}
      seoDescription={data.mdx.frontmatter.seoDescription}
      pageClass={data.mdx.frontmatter.pageClass}
      subtitle={data.mdx.frontmatter.subtitle}
      ctaText={data.mdx.frontmatter.ctaText || ''}
      image={data.mdx.fields.image}
      imageAlt={data.mdx.frontmatter.imageAlt}
      showCTA={data.mdx.frontmatter.showCTA}
      body={data.mdx.body || ''}
    />
  )
}

export default Page

export const post = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        h2Title
        pageClass
        templateKey
        showCTA
        subtitle
        seoTitle
        seoDescription
        imageAlt
        ctaText
      }
      fields {
        pageIdentifier
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
